import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Immutable from 'immutable';
import cn from 'classnames';
import guid from 'guid';
import { StyleSheet, css } from 'aphrodite';
import { withTranslation } from 'react-i18next';

import { Table, Column, Cell } from 'fixed-data-table-2';

import SCENE_CONTAINER from '../../../../configs/sceneContainer';

import userSettingsActions from '../../../../actions/userSettingsActions';

import Icon from '../Icon';
import SortHeaderCell from './SortHeaderCell';
import FooterCell from './FooterCell/index.jsx';
import CellData from './CellData';
import LinkedRecordOpen from '../../../Record/LinkedRecordOpen';
import ButtonTransparent from '../ButtonTransparent';
import Indicator from './Indicator';

import styles from './table.less';
import { getAdaptiveColors } from '../../../../utils/getAdaptiveColors.js';
import { RECORDS_PAINTER_OPACITY } from '../../../../configs/recordsPainter.js';

export const TITLE_ID = guid.raw();

class RecordsTable extends React.PureComponent {
  static propTypes = {
    fieldsToRender: PropTypes.object,
    allFields: PropTypes.object,
    records: PropTypes.object,
    recordsAppState: PropTypes.object,
    fieldsOrder: PropTypes.object,
    recordsStatus: PropTypes.object,
    footerData: PropTypes.object,
    sortField: PropTypes.string,
    sceneId: PropTypes.string,
    sortType: PropTypes.number,
    onRecordClick: PropTypes.func,
    fetchRecords: PropTypes.func,
    getCellClassName: PropTypes.func,
    isCellEditable: PropTypes.func,
    CustomHeaderComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]), // object for fix warnings
  };

  fixedColumns = [];

  state = {
    // editingRowId: null,
    catalogJustChanged: true,
    editing: false,
    editingRecordId: null,
    editingColumnId: null,
    rowToScroll: undefined,
  };

  componentDidMount() {
    // fix table resize after catalog changed when Record was open
    setTimeout(() => this.setState({ catalogJustChanged: false }), 1);
    // this.setState({ catalogJustChanged: false });
  }

  componentDidUpdate(prevProps) {
    const { records: prevRecords, shouldReload: prevShouldReload } = prevProps;
    const { records, scrollToEndByEditing, shouldReload } = this.props;

    if (scrollToEndByEditing && prevRecords && records && prevRecords.size < records.size) {
      this.scrollToLastRow();
    }

    /**
     * флаг shouldReload обрабатывается в компонентах таблицы, а тк записей нет,
     * то по всей видимости и рендер таблицы не происходит
     * поэтому для случаев, когда нам нужно обновить данные,
     * но при этом у нас нет записей, прописано это условие
     */
    if (!prevShouldReload && shouldReload && this.props.rowsCount == 0) {
      this._fetchRows(0);
    }
  }

  /* метод предназначен для получения пропсов для ячейки для того чтобы конектом не оборачивать каждую ячейку
  и не получать для каждой ячейки все записи  */
  getNewPropsForCellData = (recordByRowIndex, field) => {
    const { catalogId, recordsAppState } = this.props;

    const fieldId = field && field.get('id');

    let value;
    let recordId;
    let inProcess;
    let saving;

    recordId = recordByRowIndex && recordByRowIndex.get('id');
    value = recordByRowIndex && recordByRowIndex.getIn(['values', fieldId]);

    // при изменении значений полей через таблицу, нужно отображать изменения в таблице, поэтому берем данные из фиктивных рекордов
    // по сути, можно сохранять в стейте, но это возможно вызовет рассинхрон таблицы и самой записи, тк значение может не пройти валидацию
    const recordFromAppState = recordsAppState && recordsAppState.getIn([catalogId, recordId]);

    const fullRecordHasValue = recordFromAppState && recordFromAppState.hasIn(['values', fieldId]);

    if (field.get('id') === TITLE_ID) {
      value = recordByRowIndex && recordByRowIndex.get('text');
    } else if (fullRecordHasValue) {
      inProcess = recordFromAppState && recordFromAppState.getIn(['updateProcesses', 'fields', fieldId, 'inProcess']);

      const recordValue = recordFromAppState && recordFromAppState.getIn(['values', fieldId]);
      const originValue = recordFromAppState && recordFromAppState.getIn(['originValues', fieldId]);

      value = recordValue;
      recordByRowIndex = recordFromAppState;

      const valueHasBeenChanged = _.isObject(recordValue)
        ? !Immutable.is(recordValue, originValue)
        : recordValue !== originValue;

      saving = valueHasBeenChanged && recordFromAppState.get('saving');
    }

    return {
      value,
      record: recordByRowIndex,
      recordId,
      inProcess,
      saving,
      fieldId,
      recordExist: !!recordFromAppState,
    };
  };

  scrollToLastRow = () => {
    const { records } = this.props;
    this.setState(() => ({
      rowToScroll: records.size,
    }));
  };

  deleteRow = (record) => {
    this.props.onDeleteRow && this.props.onDeleteRow(record);
  };

  restoreRow = (record) => {
    this.props.onRestoreRow && this.props.onRestoreRow(record);
  };

  clickTimer = null;

  onRecordClick = (rowIndex) => {
    const { onRecordClick } = this.props;

    const { records } = this.props;
    const record = records.get(String(rowIndex)) || records.get(rowIndex);

    if ( this.state.editing ) {
      return;
    }

    onRecordClick && onRecordClick(record);
  }

  onRowClick = (event, rowIndex) => {
    if ( this.props.editByClick ) {
      return;
    }

    if ( this.state.editing ) {
      return;
    }

    // open record
    // разрешаем войти в режим редактирования ячейки
    clearTimeout(this.clickTimer);
    this.clickTimer = setTimeout(() => {
      this.onRecordClick(rowIndex);
    }, 250);
  };

  onCellClick = (event, rowIndex, columnKey) => {
    // edit
    // быстрое редактирование ячейки в той же строке
    // по клику, а не дабл клику
    if ( ! this.state.editing ) {
      return;
    }

    const { records } = this.props;
    const record = records.get(String(rowIndex)) || records.get(rowIndex);
    const recordId = record && record.get("id");

    if ( recordId && recordId == this.state.editingRecordId ) {
      this.setState({ editingColumnId: columnKey });
    }

  }

  onCellDoubleClick = (event, rowIndex) => {
    if ( this.props.editByClick ) {
      this.onRecordClick(rowIndex);
    }
  };

  _fetchRowsDebounced = _.debounce((rowIndex) => {
    rowIndex = Math.floor(rowIndex / 50) * 50;
    const { fetchRecords } = this.props;
    fetchRecords && fetchRecords(rowIndex, 50);
  }, 200);

  _fetchRows = (rowIndex) => {
    rowIndex = Math.floor(rowIndex / 50) * 50;
    const { fetchRecords } = this.props;
    fetchRecords && fetchRecords(rowIndex, 50);
  };

  onColumnReorderEndCallback = (event) => {
    const { reorderColumn } = event;
    let fieldsOrder = this.props.fieldsOrder || Immutable.List([]);

    // проверяем все ли значения в массиве = id поля, если есть null, то записываем id поля
    this.props.allFields.forEach((field) => {
      const fieldId = field.get('id');
      const idExist = fieldsOrder.findIndex((id) => id == fieldId) != -1;

      if (!idExist) {
        fieldsOrder = fieldsOrder.push(fieldId);
      }
    });

    // убираем id переносимой колонки
    let columnOrder = fieldsOrder.filter((id) => {
      // проверяем все ли id совпадают с id поля
      const idExist = this.props.allFields.findIndex((f) => f.get('id') == id) != -1;
      const idNotReorderableItem = id != reorderColumn;
      return idExist && idNotReorderableItem;
    });

    if (event.columnAfter) {
      // получаем индекс после какого добавляем id
      const index = columnOrder.findIndex((id) => id === event.columnAfter);
      // удаляем 0 элементов и добавляем текущую колонку в массив после индекса который мы получили ранее
      columnOrder = columnOrder.splice(index, 0, reorderColumn);
    } else {
      if (this.fixedColumns.indexOf(reorderColumn) !== -1) {
        columnOrder = columnOrder.splice(this.fixedColumns.length - 1, 0, reorderColumn);
      } else {
        columnOrder = columnOrder.push(reorderColumn);
      }
    }

    this.props.onReorder && this.props.onReorder(columnOrder);
  };

  onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
    this.props.onResize && this.props.onResize(newColumnWidth, columnKey);
  };

  getRowColorClassname = (currentRecord) => {
    const { colorField, fieldsToRender } = this.props;

    if (!colorField) {
      return;
    }

    // color by field
    const isField = colorField.get('type') === 'field';
    if (!isField) return;

    // field exists
    const userColorFieldId = colorField.get('value');
    const field = fieldsToRender.find((field) => field.get('id') == userColorFieldId);
    if (!field) return;

    // get item
    const fieldColors = field.getIn(['config', 'items']);
    if (!fieldColors) return;
    const itemId = currentRecord.getIn(['values', userColorFieldId, '0']);
    const item = fieldColors.find((item) => item.get('id') == itemId);
    // if (!item) return;

    // get color
    const color = item ? item.get('color') : 'white';

    // get color classname
    return this.getColorClassname(color);
  };

  colors = [];

  getColorClassname = (color) => {
    if (!this.colors[color]) {
      const { textColor, lighteningBackground } = getAdaptiveColors(color, RECORDS_PAINTER_OPACITY);
      this.colors[color] = StyleSheet.create({
        color: {
          backgroundColor: lighteningBackground,
        },
      }).color;
    }

    return css(this.colors[color]) || null;
  };

  rowClassNameGetter = (rowIndex) => {
    const { records, selectedRecordId, editableRecordId } = this.props;
    const currentRecord = records.get(String(rowIndex)) || records.get(rowIndex);
    if (!currentRecord) {
      return;
    }

    // get row color class
    const classes = [];
    const colorCLass = this.getRowColorClassname(currentRecord);
    colorCLass && classes.push(colorCLass);

    if (currentRecord.get('canRestore')) {
      classes.push('fixedDataTableRowDeleted');
    } else if (currentRecord.get('id') === editableRecordId) {
      classes.push('fixedDataTableRowEditable');
    } else if (currentRecord.get('id') === selectedRecordId) {
      classes.push('fixedDataTableRowSelected');
    }

    return cn(...classes);
  };

  onChange = (recordId, fieldId, value) => {
    const { onChangeRecordValue } = this.props;
    onChangeRecordValue && onChangeRecordValue(recordId, fieldId, value);
  };

  onStartEditing = (record, field) => {
    const { onStartRowEditing, onStartCellEditing } = this.props;
    const recordId = record.get('id');
    const fieldId = field.get('id');
    const currentEditingRecordId = this.state.editingRecordId;

    this.setState({ editing: true, editingRecordId: recordId, editingColumnId: fieldId });

    onStartCellEditing && onStartCellEditing(recordId, fieldId);

    if (recordId !== currentEditingRecordId) {
      onStartRowEditing && onStartRowEditing(record);
    }
  };

  onEndEditing = (recordId, fieldId, value) => {
    const { onEndCellEditing } = this.props;
    this.setState({ editing: false });

    onEndCellEditing && onEndCellEditing(recordId, fieldId, value);
  };

  onCellBlur = (recordId, columnKey) => {
    const { onEndRowEditing } = this.props;

    // разрешаем отработать клику, и чтобы он знал, что был в режим редактировании
    setTimeout(() => {
      if ( this.state.editingRecordId == recordId && this.state.editingColumnId == columnKey ) {
        this.setState({ editing: false, editingColumnId: null });
      }
    }, 200);

    
    // дадим время пользователю переключиться на редактирование другого поля той же строки
    setTimeout(() => {

      const {editing} = this.state;
      const sameRow = this.state.editingRecordId == recordId;

      if (!sameRow || !editing) {
        onEndRowEditing && onEndRowEditing(recordId);
      }

      if (!editing) {
        this.setState({ editingRecordId: null });
      }
    }, 300);
  };

  onRefuseEditing = (recordId, fieldId) => {
    this.setState({ editingRecordId: null });

    const { onRefuseEditing } = this.props;

    onRefuseEditing && onRefuseEditing(recordId, fieldId);
  };

  onScrollStart = () => {
    this.setState(() => ({
      rowToScroll: undefined,
    }));
  };

  onEditSwitchChanged = (checked) => {
    // Change editable state of table by switch
    userSettingsActions.setOption({
      catalogId: this.props.catalogId,
      viewMode: 'table',
      option: 'editable',
      value: checked,
    });
  };

  render() {
    const {
      records,
      scene,
      fieldsToRender: fields,
      sortType,
      sortField,
      onSortChange,
      fieldsWidth,
      headerHeight,

      idColumnMinWidth,
      minWidth,

      footerData,
      colorField,

      headerClassName,
      isReorderable,
      idColumnClassName,
      sortHeaderCellClassName,
      editByClick,

      getCellClassName,
      CustomHeaderComponent,

      shouldReload,
      t,
    } = this.props;

    const footer = !!footerData;
    const recordsCountNewMessages = scene && scene.get('recordsCountNewMessages');
    // In this function, we check whether the line contains an image
    // Maybe need in future)
    // const hasRowImage = rowIndex => {
    //   const { fieldsToRender } = this.props;
    //   const currentRecord = _.get(
    //     records.toJS(),
    //     [String(rowIndex), "values"],
    //     {}
    //   );
    //   let height = 35;
    //   fieldsToRender &&
    //     fieldsToRender.forEach((field, i) => {
    //       if (field.get("type") == fieldTypes.FILE) {
    //         const values = currentRecord[field.get("id")];
    //         const isImage = values.some(value => {
    //           const fileType = getFileType(value);
    //           if (_.get(fileType, "viewer.viewerName") === "ImageViewer") {
    //             return true;
    //           }
    //           return false;
    //         });
    //         if (isImage) {
    //           height = 65;
    //         }
    //       }
    //     });
    //   return height;
    // };
    return !this.state.catalogJustChanged ? (
          <Table
            className={cn({ ColoredFixedTable: colorField })}
            width={this.props.width}
            height={this.props.height}
            maxHeight={this.props.maxHeight}
            rowHeight={35} // @RECORD_DATA_HEADER
            // rowHeightGetter={hasRowImage} // if current row include image, we should be grow up this row
            footerHeight={footerData ? 28 : 0}
            rowsCount={+this.props.rowsCount || 0} // For pagination
            headerHeight={headerHeight || 35} // @RECORD_DATA_HEADER
            scrollToRow={this.state.rowToScroll}
            onScrollStart={this.onScrollStart}
            // onRowMouseDown={this.onRowClick}
            onRowClick={this.onRowClick}
            onColumnReorderEndCallback={this.onColumnReorderEndCallback}
            isColumnReordering={false}
            onColumnResizeEndCallback={this.onColumnResizeEndCallback}
            isColumnResizing={false}
            rowClassNameGetter={this.rowClassNameGetter}
            updateDimensions={this.props.updateDimensions}
            touchScrollEnabled
          >
            <Column
              columnKey="id"
              fixed
              allowCellsRecycling
              pureRendering={false}
              isResizable
              isReorderable={false}
              minWidth={idColumnMinWidth || 40}
              width={(fieldsWidth && fieldsWidth.get('id')) || 60}
              header={
                <SortHeaderCell
                  className={cn(headerClassName, idColumnClassName)}
                  sortHeaderCellClassName={sortHeaderCellClassName}
                  sorting={sortField === 'id' ? sortType : 0}
                  onSortChange={onSortChange}
                  columnKey="id"
                >
                  №
                </SortHeaderCell>
              }
              cell={({ rowIndex, ...props }) => {
                const recordByRowIndex = records.get(String(rowIndex)) || records.get(rowIndex);
                if (shouldReload) {
                  this._fetchRows(rowIndex);
                } else if (!recordByRowIndex && !this.props.loading) {
                  this._fetchRowsDebounced(rowIndex);
                }

                if (this.props.rowByIndex) {
                  const text = _.truncate(`${rowIndex + 1}`, { length: 4 });
                  return (
                    <Cell
                      {...props}
                      className={cn('fixedDataTable_fixedColumn', this.props.idColumnClassName, styles.cell)}
                    >
                      {text}
                    </Cell>
                  );
                }

                if (recordByRowIndex) {
                  const recordId = recordByRowIndex.get('id');
                  const recordIndex = _.truncate(`${rowIndex + 1}`, {
                    length: 4,
                  });

                  const text = this.props.rowByIndex ? recordIndex : recordId;
                  const linkedRecordOpen = this.props.rowByIndex ? false : this.props.withLinkToRecord;

                  const isNew = recordByRowIndex.get('isNew');
                  const recordIdParams = {
                    catalogId: this.props.catalogId,
                    recordId,
                  };

                  const indicator = recordByRowIndex.get('indicator');
                  const alert = recordByRowIndex.getIn(['chatOptions', 'newMessages']);

                  const openModalIcon = (
                    <span key="icon" className={cn('anticon-icon multimedia-12', styles.openModalIcon)} />
                  );

                  const recordIdLink = (
                    <span key="id" className={cn(styles.recordIdLink, this.props.idColumnClassName)}>
                      {!isNew && text}
                    </span>
                  );

                  return (
                    <Cell
                      onMouseDown={(event) => event.stopPropagation()}
                      {...props}
                      className={cn('fixedDataTable_fixedColumn', this.props.idColumnClassName, styles.cell)}
                    >
                      {linkedRecordOpen ? (
                        <LinkedRecordOpen
                          wrapperClassName={cn(styles.openModal, idColumnClassName, {
                            [styles.iconBadge]: alert,
                          })}
                          linkProps={{
                            children: [openModalIcon, recordIdLink],
                            title: t('record.openModal'),
                          }}
                          params={recordIdParams}
                          container={SCENE_CONTAINER.POPUP}
                        />
                      ) : (
                        recordIdLink
                      )}

                      {indicator && <Indicator indicator={indicator} />}
                    </Cell>
                  );
                }
                return (
                  <Cell
                    {...props}
                    className={cn('fixedDataTable_fixedColumn', styles.cell, this.props.idColumnClassName)}
                  >
                    ...
                  </Cell>
                );
              }}
              footer={
                footer
                  ? (props) => <FooterCell onClick={this.scrollToLastRow}>{footerData.get('id')}</FooterCell>
                  : null
              }
            />

            {fields.map((field, index) => {
              const fieldId = field.get('id');
              const width = (fieldsWidth && fieldsWidth.get(fieldId)) || 100;
              const staticField = field.get('static');

              const columnIsReorderable = staticField ? false : isReorderable;

              if (field.get('hidden')) {
                return null;
              }

              const fieldType = field.get('type');
              const alignRight = ['number', 'progress'].includes(fieldType);

              return (
                <Column
                  key={fieldId}
                  pureRendering={false}
                  allowCellsRecycling // false (сомнительно)
                  columnKey={fieldId}
                  isResizable
                  isReorderable={columnIsReorderable}
                  minWidth={minWidth || 55}
                  width={width}
                  header={
                    <SortHeaderCell
                      // className={cn(this.props.headerClassName, {
                      //   [styles.alignRight]: alignRight
                      // })}
                      className={this.props.headerClassName}
                      sorting={fieldId === sortField ? sortType : 0}
                      onSortChange={onSortChange}
                      columnKey={fieldId}
                    >
                      {CustomHeaderComponent ? CustomHeaderComponent({ field }) : field.get('name')}
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    const { rowIndex, columnKey } = props;

                    const recordByRowIndex = records.get(String(rowIndex)) || records.get(rowIndex);

                    if (!recordByRowIndex) {
                      return (
                        <Cell {...props} className={cn(styles.cell)}>
                          ...
                        </Cell>
                      );
                    }

                    const newProps = this.getNewPropsForCellData(recordByRowIndex, field);

                    const recordIsOpen = this.props.selectedRecordId === newProps.recordId;

                    const editable =
                      !recordIsOpen && this.props.isCellEditable && this.props.isCellEditable(field, recordByRowIndex);

                    const active = this.state.editingRecordId == recordByRowIndex.get("id") && this.state.editingColumnId == props.columnKey

                    return (
                      <CellData
                        height={props.height}
                        rowIndex={props.rowIndex}
                        columnKey={props.columnKey}
                        width={props.width}
                        value={newProps.value}
                        catalogId={this.props.catalogId}
                        recordId={newProps.recordId}
                        record={newProps.record}
                        recordExist={newProps.recordExist}
                        fieldId={newProps.fieldId}
                        field={field}
                        editable={editable}
                        active={active}
                        editByClick={editByClick}
                        inProcess={newProps.inProcess || newProps.saving}
                        validateValueByField={this.props.validateValueByField}
                        // className={cn({ [styles.alignRight]: alignRight })}
                        onBlur={this.onCellBlur}
                        getCellClassName={getCellClassName}
                        onClick={this.onCellClick}
                        onDoubleClick={this.onCellDoubleClick}
                        onChange={this.onChange}
                        onStartEditing={this.onStartEditing}
                        onEndEditing={this.onEndEditing}
                        onRefuseEditing={this.onRefuseEditing}
                      />
                    );
                  }}
                  footer={
                    footer
                      ? (props) => (
                          <FooterCell
                            onClick={this.scrollToLastRow}
                            className={cn({
                              [styles.alignRight]: alignRight,
                            })}
                          >
                            {footerData.get(fieldId)}
                          </FooterCell>
                        )
                      : null
                  }
                />
              );
            })}
            {this.props.removable && (
              <Column
                columnKey="remove"
                fixedRight
                allowCellsRecycling
                pureRendering={false}
                isResizable={false}
                isReorderable={false}
                minWidth={40}
                width={40}
                header={<SortHeaderCell className={styles.removeColumn} columnKey="remove" />}
                cell={({ rowIndex, ...props }) => {
                  const recordByRowIndex = records.get(String(rowIndex)) || records.get(rowIndex);
                  const canRestore = recordByRowIndex && recordByRowIndex.get('canRestore');

                  const onClick = canRestore ? this.restoreRow : this.deleteRow;

                  const title = canRestore ? t('record.fields.object.restore') : t('buttons.remove');

                  const icon = canRestore ? 'transfers-70' : 'edition-43';

                  return (
                    <Cell
                      onMouseDown={(event) => event.stopPropagation()}
                      className={cn('hiddenDataTableColumn', styles.deleteCell)}
                      {...props}
                    >
                      <ButtonTransparent
                        className={cn(styles.deleteButton)}
                        title={title}
                        onClick={(e) => {
                          e.stopPropagation();
                          onClick(recordByRowIndex);
                        }}
                      >
                        <Icon type={`icon ${icon}`} />
                      </ButtonTransparent>
                    </Cell>
                  );
                }}
              />
            )}
          </Table>
        ) : null;
  }
}

RecordsTable.defaultProps = {
  removable: true,
  withLinkToRecord: true,
};

export default withTranslation()(RecordsTable);
