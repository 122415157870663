import React from 'react';
import { TreeSelect } from 'antd';
import Immutable, { Map, List } from 'immutable';
import cn from 'classnames';
import _ from 'lodash';

import { DownOutlined } from '@ant-design/icons';
import fieldTypeIcons from '../../configs/fieldTypeIcons';
import styles from './filter.less';
import Icon from '../common/UI/Icon';
import i18n from '../../configs/i18n';

class AdditionalFilters extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      allFields: Map({}),
      isOpenSelect: false,
      treeData: [],
      treeExpandedKeys: [],
      currentInput: '',
    };
  }

  /* prepare functions */
  componentDidMount() {
    this.setAllFields();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.catalogs !== this.props.catalogs || prevProps.config !== this.props.config) {
      this.setAllFields();
    }

    if (prevProps.extendedFiltersFields !== this.props.extendedFiltersFields) {
      this.updateTreeData();
    }

    if (prevState.allFields !== this.state.allFields) {
      this.updateTreeData();
    }
  }

  getAllConfigCatalogs = () => {
    const { config, catalogs } = this.props;
    let linkedCatalogs = config.get('catalogs') || Immutable.Map([]);

    // Все каталоги связные
    if (linkedCatalogs.get(0) === '*') {
      linkedCatalogs = catalogs
        .valueSeq()
        .map((i) => i.get('id'))
        .toJS();
    } else {
      linkedCatalogs = linkedCatalogs.map((i) => i.get('id')).toJS();
    }

    let linkedViews = config.get('views') || Immutable.Map([]);
    linkedViews = linkedViews.map((i) => i.get('catalogId')).toJS();
    linkedCatalogs = _.concat(linkedCatalogs, linkedViews);
    linkedCatalogs = _.uniq(linkedCatalogs);
    return linkedCatalogs;
  };

  getCatalog = (catalogId) => {
    let catalog;
    const allCatalogs = this.props.catalogs;

    // try get from all catalogs
    catalog = allCatalogs.get(catalogId);
    if (catalog) {
      return catalog;
    }

    // try get from config catalog
    catalog = this.props.config.get('catalogs').find((i) => i.get('id') === catalogId);
    if (catalog) {
      return catalog;
    }

    // try get from config views
    const view = this.props.config.get('views').find((i) => i.get('catalogId') === catalogId);
    if (view) {
      return Immutable.Map({
        id: view.get('catalogId'),
        title: view.get('catalogTitle'),
        icon: view.get('catalogIcon'),
      });
    }

    return null;
  };

  setAllFields = () => {
    const allCatalogs = this.props.catalogs;
    const linkedCatalogs = this.getAllConfigCatalogs();

    let allFields = Map({});
    linkedCatalogs.forEach((linkedCatalogId) => {
      const linkedCatalogFields = allCatalogs.getIn([linkedCatalogId, 'fields']);
      allFields = allFields.set(linkedCatalogId, linkedCatalogFields);
    });

    this.setState({
      allFields,
    });
  };

  /* treeData's functions */
  getTreeData = () => {
    const linkedObjects = this.getAllConfigCatalogs();

    let treeData = [];
    let catalogId;
    if (linkedObjects.length > 1) {
      treeData = _.map(linkedObjects, (catalogId) => {
        const catalog = this.getCatalog(catalogId);
        const catalogName = catalog.get('title') || catalog.get('name');
        const catalogIcon = catalog.get('icon');
        const childrens = this.getTreeCatalog(catalogId);

        return {
          key: catalogId,
          value: catalogId,
          title: catalogName,
          children: childrens,
          selectable: false,
          icon: <Icon className={styles.filterAdditionalIcon} type={`icon ${catalogIcon}`} />,
        };
      });
    } else {
      // eslint-disable-next-line prefer-destructuring
      catalogId = linkedObjects[0];
      treeData = this.getTreeCatalog(catalogId);
    }

    return treeData;
  };

  getTreeCatalog = (catalogId) => {
    const fields = this.props.catalogs.getIn([catalogId, 'fields']) || List([]);
    const result = [];

    let currentParent = null;
    for (const item of fields) {
      if (item.get('hidden')) {
        continue;
      }
      const treeItem = this.getTreeField(item, catalogId);

      if (treeItem.children) {
        currentParent = treeItem;
        result.push(treeItem);
      } else {
        if (!currentParent) {
          result.push(treeItem);
        } else {
          currentParent.children.push(treeItem);
        }
      }
    }

    const filteredEmptySections = result.filter((i) => !i.children || i.children.length > 0);

    return filteredEmptySections;
  };

  getTreeField = (item, linkedCatalogId) => {
    const catalogId = linkedCatalogId || '';
    const fieldId = item.get('id');
    const fieldName = item.get('name');
    const fieldType = item.get('type');
    const fieldIcon = fieldTypeIcons[fieldType];

    // get installed fields for treeSelect (selectable option)
    const selectedFields = this.props.extendedFiltersFields.get(catalogId) || List([]);
    if (fieldType === 'group') {
      return {
        expanded: true,
        key: `${catalogId}:${fieldId}`,
        value: `${catalogId}:${fieldId}`,
        title: fieldName,
        selectable: false,
        children: [],
      };
    }
    return {
      key: `${catalogId}:${fieldId}`,
      value: `${catalogId}:${fieldId}`,
      title: fieldName,
      icon: <Icon className={styles.filterAdditionalIcon} type={`icon ${fieldIcon}`} />,
      disabled: selectedFields && selectedFields.some((selectedField) => selectedField.get('id') === fieldId),
    };
  };

  updateTreeData = () => {
    this.setState({ treeData: this.getTreeData() });
  };

  /* change functions */
  onSelect = (value) => {
    if (_.isEmpty(value)) return;
    const [catalogId, fieldId] = value.split(':');

    // save field filter
    const catalogFields = this.state.allFields.get(catalogId) || List([]);
    let selectedItem = catalogFields.find((item) => fieldId === item.get('id'));
    selectedItem = selectedItem.setIn(['config', 'opened'], true);
    this.props.onSelect(catalogId, selectedItem);

    // close input
    this.onBlurInput();
  };

  onTreeExpand = (value) => {
    this.setState({ treeExpandedKeys: value });
  };

  /* helpers functions */
  onFocusInput = (e) => {
    e &&
      e.target &&
      this.setState({
        currentInput: e.target,
      });

    setTimeout(() => {
      this.setState({
        isOpenSelect: true,
      });
    });
  };

  onBlurInput = () => {
    const input = this.state.currentInput;
    input && input.blur(); // remove blur from input component after select
    this.setState({ isOpenSelect: false });
  };

  render() {
    return (
      <div className={styles.filterAdditionalContainer}>
        <TreeSelect
          treeData={this.state.treeData}
          value={[]}
          treeExpandedKeys={this.state.treeExpandedKeys}
          className={cn(styles.filterAdditionalFields)}
          placeholder={i18n.t('filter.additionalFilterPlaceholder')}
          treeExpandAction="click"
          treeNodeFilterProp="title"
          open={this.state.isOpenSelect}
          bordered={this.state.isOpenSelect}
          showArrow={this.state.isOpenSelect}
          suffixIcon={<DownOutlined />}
          listHeight={350}
          dropdownStyle={{ maxHeight: '450px' }}
          allowClear
          showSearch
          treeIcon
          treeLine
          onChange={this.onSelect}
          onFocus={this.onFocusInput}
          onBlur={this.onBlurInput}
          onTreeExpand={this.onTreeExpand}
        />
      </div>
    );
  }
}

export default AdditionalFilters;
