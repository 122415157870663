import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import Category from '../UI/Category';

import styles from './dataTypes.less';
import { getContrastedColor } from '../../../utils/getContrastedColor';

const CategoryField = (props) => {
  const values = [];
  let fieldValues = _.isObject(props.value) ? props.value.toJS() : props.value;
  if (!_.isArray(fieldValues)) {
    fieldValues = fieldValues ? [fieldValues] : [];
  }
  fieldValues.forEach((val) => {
    const item = props.config.get('items').find((item) => item.get('id') === val);
    if (!item) {
      return;
    }
    values.push({
      name: item.get('name'),
      color: `#${item.get('color') || 'dfe0e0'}`, // color from catalogEditor
    });
  });

  return (
    <div className={cn(props.containerClassName, styles.listField)}>
      {values.map((value, i) => {
        const color = getContrastedColor(value.color);
        return (
          <Category
            key={i}
            title={value.name}
            readOnly
            checked
            backgroundColor={value.color}
            className={styles.categoryItem}
            color={color}
          />
        );
      })}
    </div>
  );
};

CategoryField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  config: PropTypes.object,
  // fieldId: PropTypes.string.isRequired,
  // fieldType: PropTypes.string.isRequired
};

export default CategoryField;
