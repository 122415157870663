import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Button, Collapse } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import cn from 'classnames';

import styles from './page-error.module.less';
import { connect } from '../../../StateProvider';

const PageError = ({ error, company, user }) => {
  const { appVersion, apiVersion } = (company && typeof company.toJS === 'function' ? company.toJS() : {}) || {};
  const { id: userId } = (user && typeof user.toJS === 'function' ? user.toJS() : {}) || {};
  const currentDateTime = new Date().toLocaleString();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);

  const reloadPage = () => location.reload();

  const copyError = async () => {
    if (error) {
      await navigator.clipboard.writeText(error.stack || error.message);
    }
  };

  return (
    <div className={cn(styles.pageErrorWrapper)}>
      <div className={cn(styles.pageErrorContent)}>
        <h1 className={cn(styles.pageErrorTitle)}>{t('exceptions.somethingWrong') || 'Something went wrong'}</h1>
        <p className={cn(styles.pageErrorInfoItem)}>
          {`${t('exceptions.reports.currentUrl') || 'Current url'}: ${window.location.href}`} <br /> <br />
          {`${t('exceptions.reports.date') || 'Current date'}: ${currentDateTime || 'not found'}`}
        </p>

        {error && (
          <div className={cn(styles.errorDetails)}>
            <Collapse
              activeKey={expanded ? ['1'] : []}
              defaultActiveKey={['1']}
              onChange={() => setExpanded(!expanded)}
            >
              <Collapse.Panel header={t('exceptions.reports.details') || 'details'} key="1">
                {/* cause this optional data may be undefined */}
                {(appVersion || apiVersion || userId) && (
                  <div className={cn(styles.hiddenErrorInfo)}>
                    {appVersion && (
                      <p>{`${t('exceptions.reports.appVersion') || 'App version'}: ${appVersion || 'not found'}`}</p>
                    )}
                    {apiVersion && (
                      <p>{`${t('exceptions.reports.apiVersion') || 'Api version'}: ${apiVersion || 'not found'}`}</p>
                    )}
                    {userId && <p>{`${t('exceptions.reports.userId') || 'User ID'}: ${userId || 'not found'}`}</p>}
                  </div>
                )}
                <pre className={cn(styles.errorStack)}>{error.stack || error.message}</pre>
                <div className={cn(styles.errorDetailsContent)}>
                  <Button icon={<CopyOutlined />} onClick={copyError}>
                    {t('buttons.copyError') || 'Copy error'}
                  </Button>
                  <p
                    className={cn(styles.sendReportLabel)}
                  >{`🙏 ${t('exceptions.reports.reportErrorTo') || 'Send error message to'}: support@bpium.ru`}</p>
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>
        )}
        <Button onClick={reloadPage}>{t('buttons.reloadPage') || 'Reload page'}</Button>
      </div>
    </div>
  );
};

export default connect(
  PageError,
  {
    company: ['company'],
    user: ['user'],
  },
  (props, { company, user }) => ({
    company,
    user,
    ...props,
  }),
);
