import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable, { fromJS } from 'immutable';
import _ from 'lodash';

import LinkedRecordsInTableMode from './LinkedRecordsInTableMode';
import LinkedRecordsInCardMode from './LinkedRecordsInCardMode';
import LinkedRecordsInListMode from './LinkedRecordsInListMode';
import LinkedRecordSelector from './LinkedRecordSelector';

import useLinkedItems from '../../hooks/useLinkedItems.hook';
import { OBJECT_MODS } from '../../../../../../configs/fieldTypes';
import { connect } from '../../../../../StateProvider';
import recordActions from '../../../../../../actions/recordActions';

const listViews = {
  [OBJECT_MODS.TABLE]: LinkedRecordsInTableMode,
  [OBJECT_MODS.CARDS]: LinkedRecordsInCardMode,
  [OBJECT_MODS.LIST]: LinkedRecordsInListMode,
  [OBJECT_MODS.INLINE]: LinkedRecordsInListMode,
};

const RecordDropdown = (props) => {
  const controlConfig = props.controlConfig || Immutable.Map();
  const value = props.value || Immutable.List();
  const { config } = props;
  const multiselect = config.get('multiselect');
  const viewMode = controlConfig.getIn(['config', 'mode']);
  const valueIsEmpty = !value || value.size === 0;
  const type = controlConfig.get('elementsRemoteGroup') || props.remoteGroup;
  const additionalItems = _.concat([], props.additionalClickItems || [], props.additionalItems || []);

  const { items, addItem, removeItem, restoreItem, deleteRestoreItem } = useLinkedItems(config, value);

  const onAddItem = (item) => {
    const newValue = !_.isEmpty(item) ? addItem(fromJS(item[0])) : null;
    if (newValue) {
      props.onChange && props.onChange(newValue);
      props.onEndEditing && props.onEndEditing(newValue);
    }
  };

  const filterFn = (item) => !(value && value.find((i) => i.key === item.key));

  // удаление элемента
  const onRemoveItem = (item) => {
    if (item.get('canRestore')) {
      deleteRestoreItem(item.get('key'));
    } else {
      const newItems = removeItem(item.get('key'));
      props.onChange && props.onChange(newItems);
      props.onEndEditing && props.onEndEditing(newItems);
    }
  };

  const onRestoreItem = (item) => {
    const newItems = restoreItem(item.get('key'));

    if (newItems) {
      props.onChange && props.onChange(newItems);
      props.onEndEditing && props.onEndEditing(newItems);
    }
  };

  const [preparedExtendentFields, setPreparedExtendentFields] = useState(config.get('fields'));

  useEffect(() => {
    const { catalogs } = props;
    const fields = props.config.get('fields');

    // Применение конфигов из appState в случае если с сервера придут не те данные
    const extendedFields =
      fields &&
      fields.map((extendedFieldByCatalog, catalogId) =>
        extendedFieldByCatalog.map((extendedField) => {
          const catalogFields = catalogs.getIn([catalogId, 'fields']);

          // Get needed field
          const catalogField =
            catalogFields && catalogFields.find((catalogField) => catalogField.get('id') === extendedField.get('id'));

          if (catalogField) {
            // Get appState filed configs
            const fieldConfig = catalogField.get('config');

            // Set appState configs to props fields
            extendedField = extendedField.set('config', fieldConfig);
            extendedField = extendedField.set('eventable', catalogField.get('eventable'));
          }
          /** ограничение вложенности на 2 уровне
           *  как аналог можно сменить тип отображения (только делать это нужно вне циклов)
           *  config = props.config.set("mode", OBJECT_MODS.LIST)
           */

          extendedField = extendedField.setIn(['config', 'fields'], Immutable.fromJS([]));

          extendedField = extendedField.setIn(['config', 'mode'], OBJECT_MODS.LIST);

          const editable = extendedField.get('editable');
          extendedField = extendedField.set('readOnly', !editable);
          extendedField = extendedField.set('viewOnly', !editable);
          extendedField = extendedField.set('extended', true);
          return extendedField;
        }),
      );
    setPreparedExtendentFields(extendedFields);
  }, [config.get('fields')]);
  /* объявление компонента для дальнейшей передачи по пропсам, тк каждой таблице нужен свой выпадающий список */
  let RecordSelect = null;
  if (!props.readOnly && !props.apiOnly && (multiselect || valueIsEmpty)) {
    RecordSelect = (
      <LinkedRecordSelector
        containerRef={props.containerRef}
        wrapperClassName={props.wrapperClassName}
        apiOnly={props.apiOnly}
        config={config}
        type={type}
        openedSelect
        requestParams={props.requestParams}
        loadAvailableItems={props.loadAvailableItems}
        clearAvailableItems={props.clearAvailableItems}
        filterable={props.filterable}
        filterFn={filterFn}
        onAddItem={onAddItem}
      />
    );
  }
  const List = preparedExtendentFields
    ? listViews[viewMode] || listViews[OBJECT_MODS.LIST]
    : listViews[OBJECT_MODS.LIST];

  return (
    <List
      sceneId={props.sceneId}
      controlConfig={controlConfig}
      remoteGroup={type}
      items={items}
      records={props.records}
      extendedFields={preparedExtendentFields}
      additionalItems={additionalItems} /* элементы по типу "[Сотрудник.Я]" и "Добавить запись" */
      apiOnly={props.apiOnly}
      readOnly={props.readOnly}
      onRemoveItem={onRemoveItem}
      onRestoreItem={onRestoreItem}
      RecordSelect={RecordSelect} /* <LinkedRecordSelector/> */
    />
  );
};

RecordDropdown.propTypes = {
  value: PropTypes.object,
  field: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  requestParams: PropTypes.object,
  editable: PropTypes.bool,
  readOnly: PropTypes.bool,
  apiOnly: PropTypes.bool,
  additionalItems: PropTypes.array,
  additionalClickItems: PropTypes.array,
};

export default connect(RecordDropdown, ['catalogs', 'records']);
