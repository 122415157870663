// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".view__container___QQqGn {\n  position: relative;\n  min-height: inherit;\n  height: 100%;\n  max-width: 100%;\n  flex-grow: 1;\n}\n/* MEDIA QUERIES */\n@media (max-width: 992px) {\n  .view__container___QQqGn {\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/AppBody/Section/SectionBody/Catalog/View/view.less"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;AAAF;AACA,kBAAkB;AAGlB;EACE;IACE,WAAA;EADF;AACF","sourcesContent":["@import (reference) '../../../../../../../styles/helpers/index.less';\n.container {\n  position: relative;\n  min-height: inherit;\n  height: 100%;\n  max-width: 100%;\n  flex-grow: 1;\n}\n\n/* MEDIA QUERIES */\n@media (max-width: 992px) {\n  .container {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "view__container___QQqGn"
};
export default ___CSS_LOADER_EXPORT___;
