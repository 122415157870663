import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Space } from 'antd';
import { withRouter } from 'react-router';

import { getContrastedColor } from '../../../../../utils/getContrastedColor';
import ButtonItem from './Button/ButtonItem';

class ButtonControl extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.config.get('items'),
    };
  }

  onClick = (itemId) => {
    const { eventable } = this.props;
    /* По флагу определяем, что у нас есть событие на изменение */
    if (eventable) {
      this.props.onChange && this.props.onChange(String(itemId));
      this.props.onEndEditing && this.props.onEndEditing(String(itemId));
    }
  };

  render() {
    const { eventable } = this.props;
    return (
      <Space wrap>
        {this.state.items.map((item) => {
          const id = item.get('id');
          const disabled = (this.props.updateProcess && this.props.updateProcess.get('inProcess')) || !eventable;
          const backgroundColor = `#${item.get('color')}`;
          const color = getContrastedColor(backgroundColor);
          return (
            <ButtonItem
              onClick={_.bind(this.onClick, this, id)}
              backgroundColor={backgroundColor}
              color={color}
              disabled={disabled}
              icon={item.get('icon')}
            >
              {item.get('name')}
            </ButtonItem>
          );
        })}
      </Space>
    );
  }
}

ButtonControl.propTypes = {
  apiOnly: PropTypes.bool,
  config: PropTypes.object,
  controlConfig: PropTypes.object,
  editable: PropTypes.bool,
  error: PropTypes.bool,
  eventable: PropTypes.bool,
  hint: PropTypes.string,
  htmlId: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  params: PropTypes.object,
  readOnly: PropTypes.bool,
  sceneId: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default withRouter(ButtonControl);
