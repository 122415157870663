import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

const WrappedDatePicker = ({ children, format, onBlur, onChange, ...otherProps }) => {
  // useRef используем для хранения значений между рендерами
  const hash = useRef(null);

  const curInput = useRef('');
  // чтобы функция не вызывалась
  if (hash.current === null) {
    hash.current = uuidv4();
  }
  useEffect(() => {
    const curElement = document.getElementById(hash.current);

    const listener = (e) => {
      curInput.current = e.target.value;
    };

    curElement.addEventListener('input', listener);

    return () => {
      curElement.removeEventListener('input', listener);
    };
  }, []);

  const onHandleBlur = (e) => {
    moment(curInput.current, format, true).isValid() && onHandleChange(moment(curInput.current, format, true));
    onBlur && onBlur(e);
  };

  const onHandleChange = (val) => {
    curInput.current = '';
    onChange && onChange(val);
  };

  return (
    <DatePicker id={hash.current} format={format} onChange={onHandleChange} onBlur={onHandleBlur} {...otherProps}>
      {children}
    </DatePicker>
  );
};

export default WrappedDatePicker;
