import React from 'react';
import { Dropdown, Row, Upload, message, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import mime from 'mime-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import cn from 'classnames';
import routes from '../../routes';
import getLink from '../common/router/getLink';
import LoadingSpin from '../common/LoadingSpin';
import ButtonClose from '../common/UI/ButtonClose';
import ImportData from './Import';

import Icon from '../common/UI/Icon';
import { renderModaltoBody, confirm } from '../common/Modal';

import recordActions from '../../actions/recordActions';
import userSettingsActions from '../../actions/userSettingsActions';
import sceneActions from '../../actions/sceneActions';
import PRIVILEGE_CODES from '../../configs/privilegeCodes';
import RESOURCE_TYPES from '../../configs/resourceTypes';
import FIELD_TYPES from '../../configs/fieldTypes';
import { checkAccessOnObject } from '../../utils/rights';
import { connect } from '../StateProvider';
import TimerLabel from './Timer/TimerLabel';
import AutoRefreshButton from './Timer/AutoRefreshButton';

import styles from './records.less';

class RecordsActivities extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      dropDownButton: false,
    };
  }

  static propTypes = {
    catalog: PropTypes.object,
    scene: PropTypes.object,
    viewId: PropTypes.string,
    sceneId: PropTypes.string,
  };

  /* действие для выполнения обновления данных по флагу shouldReload */
  btnRefresh = () => {
    const { sceneId } = this.props;
    recordActions.setShouldReload(sceneId, true);
    recordActions.setTimeUntilTimerEnd(sceneId, Date.now() + 10 * 60 * 1001);
    /*     const viewId = this.props.viewId;
    recordActions.requestForRecordsImmediate(
      catalogId,
      sceneId,
      { viewId: viewId },
      this.props.viewMode
    ); */
  };

  btnExport = (e) => {
    const { viewId, sceneId } = this.props;
    const fields = this.calcFieldsToRender();

    recordActions.requestForExportRecords({ sceneId, viewId }, { fields });
  };

  btnImport = (e) => {
    const { file } = e;
    const { catalogId, sectionId, sceneId } = this.props;
    if (file) {
      renderModaltoBody(ImportData, { file, catalogId, sectionId, sceneId });
    } else {
      message.error('failed to load data');
    }
  };

  btnRecordsBatchUpdate = (e) => {
    const { catalogId, sectionId, scene, viewId } = this.props;
    const parentSceneId = scene.get('sceneId');

    const params = {
      catalogId,
      sectionId,
      viewId,
    };

    this.setState({ dropDownButton: false });
    sceneActions.openRecordsBatchUpdate(parentSceneId, params);
  };

  btnRecordsBatchDelete = (e) => {
    const { catalogId, sectionId, scene, t, recordsCount, viewId } = this.props;
    const parentSceneId = scene.get('sceneId');

    const params = {
      catalogId,
      sectionId,
      viewId,
    };

    confirm({
      headerText: t('batchDeleteRecords.confirmation.headerText'),

      text: `${t('batchDeleteRecords.confirmation.text')} ${t('record.groupRecords.count', { count: +recordsCount })}?`,
      okText: t('batchDeleteRecords.confirmation.okText'),
      cancelText: t('batchDeleteRecords.confirmation.cancelText'),
      onOk: () => {
        this.setState({ dropDownButton: false });
        sceneActions.openRecordsBatchDelete(parentSceneId, params);
      },
    });
  };

  /* формирование списка полей, в случае отсутствия в списке, добавляется в начало */
  calcFieldsToRender = () => {
    // set ordering by default.
    const { userSettingsfields, catalog, fieldsOrder } = this.props;
    const _fieldsOrder = fieldsOrder ? fieldsOrder.toJS() : [];

    const fields = catalog.get('fields');
    let fieldsToRender = [];

    if (fields) {
      fields.forEach((field) => {
        const type = field.get('type');
        const colId = field.get('id');
        let visible = userSettingsfields && userSettingsfields.getIn([colId, 'visible', 'visible']);
        visible = visible === undefined ? true : visible;

        if (!visible || type === FIELD_TYPES.GROUP) {
          return;
        }

        fieldsToRender.push(colId);
      });

      fieldsToRender = fieldsToRender
        .map((field) => {
          // get index from collection
          const index = _fieldsOrder.indexOf(field);
          return {
            field,
            order: index !== -1 ? index : 9999,
          };
        })
        // apply orderFields from user settings.
        .sort((f1, f2) => f1.order - f2.order)
        .map((o) => o.field);
    }

    return fieldsToRender;
  };

  btnAddRecord = () => {
    const { createInPopup, catalogId, viewId, sceneId, history, location, openRecordsInModal, isWebForm } = this.props;

    /*
    // obsolet: open record as new page
    if (isWebForm) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('action', ACTION_RECORD_NEW);
      const queryString = urlParams.toString();
  
      // Получаем текущий хост и путь без параметров запроса
      const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  
      // Формируем новую URL с обновленными параметрами запроса
      const newUrl = `${baseUrl}?${queryString}`;

      // Переходим на новую URL
      window.location.href = newUrl;
    }
    */
    if (createInPopup || openRecordsInModal) {
      sceneActions.openNewRecord({ catalogId, viewId, parentSceneId: sceneId });
    } else {
      history.push({
        pathname: `${getLink(location, routes.records).pathname}/$new`,
        search: location.search,
      });
    }
  };

  showTimerAutoUpdate = (e) => {
    e.stopPropagation();
    userSettingsActions.setOption({
      catalogId: this.props.catalogId,
      viewMode: 'table',
      option: 'timer',
      value: !this.props.userSettingsTimer,
    });
  };

  getDropDownButtons = () => {
    const { catalog, viewId, recordsCount, t, sceneViews, catalogView } = this.props;
    const views = catalogView
      ? catalogView.merge(sceneViews) // .merge(sceneViews)
      : sceneViews;
    const view = views && views.get(viewId);

    let dropDownButtonItems = [];

    // batchDelete button
    /* ----------------------------------------------------------------------- */

    const viewAccessDelete = view && checkAccessOnObject(RESOURCE_TYPES.VIEW, view, PRIVILEGE_CODES.DELETE);

    const catalogAccessDelete = checkAccessOnObject(RESOURCE_TYPES.CATALOG, catalog, PRIVILEGE_CODES.DELETE);

    if (catalogAccessDelete || viewAccessDelete) {
      dropDownButtonItems = [
        {
          text: t('batchDeleteRecords.buttons.delete') + t('record.groupRecords.count', { count: +recordsCount || 0 }),
          icon: 'edition-43',
          onClick: this.btnRecordsBatchDelete,
          className: styles.settingRemove,
          disabled: !Number(recordsCount),
        },
      ].concat(dropDownButtonItems);
    }

    // export button for view
    /* ----------------------------------------------------------------------- */

    const viewAccessExport = view && checkAccessOnObject(RESOURCE_TYPES.VIEW, view, PRIVILEGE_CODES.EXPORT);

    const catalogAccessExport = checkAccessOnObject(RESOURCE_TYPES.CATALOG, catalog, PRIVILEGE_CODES.EXPORT);

    if (catalogAccessExport || viewAccessExport) {
      dropDownButtonItems = [
        {
          text: t('buttons.export'),
          icon: 'files-48',
          onClick: this.btnExport,
        },
      ].concat(dropDownButtonItems);
    }

    /* ----------------------------------------------------------------------- */

    // import button
    /* ----------------------------------------------------------------------- */

    const viewAccessCreate = view && checkAccessOnObject(RESOURCE_TYPES.VIEW, view, PRIVILEGE_CODES.CREATE);

    const catalogAccessCreate = checkAccessOnObject(RESOURCE_TYPES.CATALOG, catalog, PRIVILEGE_CODES.CREATE);

    if (catalogAccessCreate || viewAccessCreate) {
      const extensions = ['.xlsx'];
      const mimeTypes = _.map(extensions, (extension) => mime.lookup(extension)) || [];
      const acceptedTypes = [...mimeTypes, ...extensions].join(', ');
      dropDownButtonItems = [
        {
          text: t('buttons.import'),
          icon: 'files-44',
          import: true,
          acceptedTypes /* форматы допустимых к загрузке файлов */,
          onClick: this.btnImport,
        },
      ].concat(dropDownButtonItems);
    }

    // batchUpdate button
    /* ----------------------------------------------------------------------- */

    const viewAccessEdit = view && checkAccessOnObject(RESOURCE_TYPES.VIEW, view, PRIVILEGE_CODES.CREATE);

    const catalogAccessEdit = checkAccessOnObject(RESOURCE_TYPES.CATALOG, catalog, PRIVILEGE_CODES.CREATE);

    if (viewAccessEdit || catalogAccessEdit) {
      dropDownButtonItems = [
        {
          text: t('batchUpdateRecords.buttons.update') + t('record.groupRecords.count', { count: +recordsCount || 0 }),
          icon: 'edition-30',
          onClick: this.btnRecordsBatchUpdate,
          disabled: !Number(recordsCount),
        },
      ].concat(dropDownButtonItems);
    }

    /* ----------------------------------------------------------------------- */

    dropDownButtonItems = [
      {
        text: <TimerLabel scene={this.props.scene} catalogId={this.props.catalogId} />,
        onClick: this.showTimerAutoUpdate,
      },
    ].concat(dropDownButtonItems);

    const dropdownMenu = dropDownButtonItems.map((item, i) => {
      if (item.import) {
        return {
          key: i,
          className: cn(styles.dropDownButton, item.className || null),
          label: (
            <Upload
              customRequest={item.onClick}
              accept={item.acceptedTypes}
              showUploadList={false}
              className={styles.upload}
            >
              {item.icon ? <Icon type={`icon ${item.icon}`} className={styles.settingIcon} /> : null}
              {item.text}
            </Upload>
          ),
        };
      }
      return {
        key: i,
        className: cn({ [styles.dropDownButton]: !item.disabled }),
        disabled: item.disabled,
        label: (
          <a onClick={item.onClick} className={item.className}>
            {item.icon ? <Icon type={`icon ${item.icon}`} className={styles.settingIcon} /> : null}
            {item.text}
          </a>
        ),
      };
    });

    return dropdownMenu;
  };

  // create record button
  getCreateButton = () => {
    const { catalog, catalogView, sceneViews } = this.props;
    const views = catalogView
      ? catalogView.merge(sceneViews) // .merge(sceneViews)
      : sceneViews;

    const isAccessCreateRecordAtCatalog = checkAccessOnObject(
      RESOURCE_TYPES.CATALOG,
      catalog,
      PRIVILEGE_CODES.CREATE,
      // PRIVILEGE_CODES.EDIT
    );

    const isAccessCreateRecordAtViews =
      views && views.find((v) => checkAccessOnObject(RESOURCE_TYPES.VIEW, v, PRIVILEGE_CODES.CREATE));

    if (isAccessCreateRecordAtCatalog || isAccessCreateRecordAtViews) {
      return {
        text: this.props.t('buttons.add'),
        onClick: this.btnAddRecord,
      };
    }
  };

  handleOpenMenu = (open) => {
    this.setState({ dropDownButton: open });
  };

  render() {
    const { scene, onClose, withCross, viewId } = this.props;

    const recordsCountNewMessages = scene && scene.get('recordsCountNewMessages');
    const visibleFilterNewMessage = !!(recordsCountNewMessages && recordsCountNewMessages !== 0);

    const dropDownButtonItems = this.state.dropDownButton ? this.getDropDownButtons() : [];
    const createButton = this.getCreateButton();

    return (
      <Row>
        <AutoRefreshButton scene={scene} btnRefresh={this.btnRefresh} catalogId={this.props.catalogId} />
        {createButton ? (
          <>
            <Button onClick={this.btnRefresh} title={this.props.t('buttons.refresh')}>
              <LoadingSpin spin={scene && scene.get('loading')} className={styles.buttonRefresh} />
            </Button>
            <Dropdown.Button
              trigger={['click']}
              type="primary"
              overlayClassName={styles.dropDownMenu}
              onClick={createButton.onClick}
              onOpenChange={this.handleOpenMenu}
              menu={{ items: dropDownButtonItems }}
              style={{ marginLeft: '10px' }}
              icon={<DownOutlined />}
            >
              <Icon type="icon interface-72" />
              {createButton.text}
            </Dropdown.Button>{' '}
          </>
        ) : (
          <Dropdown.Button
            trigger={['click']}
            onClick={this.btnRefresh}
            onOpenChange={this.handleOpenMenu}
            menu={{ items: dropDownButtonItems }}
            style={{ marginLeft: '10px' }}
            icon={<DownOutlined />}
          >
            <LoadingSpin spin={scene && scene.get('loading')} className={styles.buttonRefresh} />
          </Dropdown.Button>
        )}
        {withCross && <ButtonClose onClick={onClose} shiftRight />}
      </Row>
    );
  }
}
export default withTranslation()(
  connect(
    RecordsActivities,
    {
      userSettings: ['userSettings', 'catalogs'],
      scenes: ['scenes'],
    },
    (props, { userSettings, scenes }) => {
      const catalogId = props.catalog.get('id');
      const sectionId = props.catalog.get('sectionId');
      const scene = scenes.get(props.sceneId);
      const recordsCount = scene && scene.get('recordsCount');
      const sceneViews = scene && scene.get('views');
      return {
        fieldsOrder: userSettings.getIn([catalogId, 'viewMode', 'table', 'fieldsOrder', 'fieldsOrder']),
        userSettingsfields: userSettings.getIn([catalogId, 'viewMode', 'table', 'fields']),
        userSettingsTimer: userSettings.getIn([catalogId, 'viewMode', 'table', 'options', 'timer', 'value']),
        scene,
        catalogView: props.catalog.get('views'),
        sceneViews,
        catalogId,
        sectionId,
        recordsCount,
        ...props,
        match: null,
      };
    },
  ),
);
