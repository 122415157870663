import tinycolor from 'tinycolor2';
import { getContrastedColor } from './getContrastedColor';

export const getAdaptiveColors = (backgroundColor, alpha = 1) => {
  const lighteningBackground = tinycolor(backgroundColor).setAlpha(alpha).toString();
  let textColor;

  if (alpha >= 0.4) {
    textColor = tinycolor(getContrastedColor(lighteningBackground, 70)).toString('hex');
  } else if (alpha >= 0.2) {
    textColor = tinycolor(getContrastedColor(lighteningBackground, 50)).toString('hex');
  } else {
    textColor = tinycolor(getContrastedColor(lighteningBackground, 30)).toString('hex');
  }
  return { textColor, lighteningBackground };
};
