import React, { useCallback, useMemo } from 'react';
import Immutable, { fromJS } from 'immutable';
import { AutoSizer } from 'react-virtualized';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import recordActions from '../../../../../../../actions/recordActions';
import sceneActions from '../../../../../../../actions/sceneActions';

import LinkedRecordsInListMode from '../LinkedRecordsInListMode';
import RecordsTable, { TITLE_ID } from '../../../../RecordsTable';

import FieldFactory from '../../../../../../../models/FieldFactory';
import FIELD_TYPES from '../../../../../../../configs/fieldTypes';
import { connect } from '../../../../../../StateProvider';

import styles from '../../controls.less';

// change resize in case of creating linkedRecord by record in modal with "всплытие"
class Table extends React.Component {
  render() {
    return <AutoSizer>{({ height, width }) => <RecordsTable width={width} {...this.props} />}</AutoSizer>;
  }
}

const LinkedRecordsTable = (props) => {
  const { t } = useTranslation();

  const onRemoveItem = useCallback(
    (record) => {
      try {
        const recordId = record.get('id');
        const catalogId = record.get('catalogId');
        const item = props.items.find(
          (item) => item.getIn(['item', 'recordId']) == recordId && item.getIn(['item', 'catalogId']) == catalogId,
        );

        props.onRemoveItem(item);
      } catch (error) {
        console.warn(error);
      }
    },
    [props.onRestoreItem, props.items],
  );

  const onRestoreItem = useCallback(
    (record) => {
      try {
        const recordId = record.get('id');
        const catalogId = record.get('catalogId');
        const item = props.items.find(
          (item) => item.getIn(['item', 'recordId']) == recordId && item.getIn(['item', 'catalogId']) == catalogId,
        );

        props.onRestoreItem(item);
      } catch (error) {
        console.warn(error);
      }
    },
    [props.onRestoreItem, props.items],
  );

  const isCellEditable = (field, record) => {
    // check editable
    const { readOnly } = props;
    const editable = field && !readOnly && field.get('editable');
    if (!editable) {
      return false;
    }

    // check Api Only
    const apiOnly = field && field.get('apiOnly');
    if (apiOnly) {
      return false;
    }

    return true;
  };

  const getFictitiousField = () =>
    FieldFactory.create(
      {
        type: FIELD_TYPES.TEXT,
        name: 'Название',
        id: TITLE_ID,
      },
      null,
      t,
    );

  const getFieldsWithOrder = (fields, fieldsOrder) => {
    let sortedFields = Immutable.List();
    fields.forEach((field) => {
      if (field.get('type') === FIELD_TYPES.GROUP) return;

      const index = fieldsOrder.indexOf(field.get('id'));
      const order = index !== -1 ? index : 9999;
      sortedFields = sortedFields.push({ order, field });
    });

    return sortedFields;
  };

  const sortFields = (fields, userSettings) => {
    let fieldsOrder = userSettings.getIn(['fieldsOrder', 'fieldsOrder']);
    fieldsOrder = fieldsOrder ? fieldsOrder.toJS() : [];

    const sortedFields = getFieldsWithOrder(fields, fieldsOrder);

    return sortedFields.sort((field1, field2) => field1.order - field2.order).map((object) => object.field);
  };

  const prepareFieldsToRender = useCallback(
    (fields, userSettings = Immutable.Map()) =>
      !fields || !fields.size ? Immutable.List([getFictitiousField()]) : sortFields(fields, userSettings),
    [props.extendedFields, props.userSettings],
  );

  const onRecordClick = (record) => {
    const { catalogId, sceneId } = props;
    const recordId = record.get('id');
    sceneActions.openRecord({ catalogId, recordId, parentSceneId: sceneId });
  };

  const prepareWidth = useCallback(
    (userSettings = Immutable.Map()) => {
      const userSettingsFields = userSettings.get('fields');

      if (!userSettingsFields) return;

      const fieldsWidth = userSettingsFields.map((setting) => setting.getIn(['width', 'width']));

      return fieldsWidth;
    },
    [props.extendedFields, props.userSettings],
  );

  const mixCatalogId =
    (callback) =>
    (...args) =>
      callback(props.catalogId, ...args);

  const onChange = (recordId, fieldId, value) => {
    const values = fromJS({ [fieldId]: value });
    const { catalogId } = props;

    recordActions.updateValues(catalogId, recordId, values);
  };

  const onEndEditing = (recordId, fieldId, value) => {
    const { catalogId } = props;
    const values = fromJS({ [fieldId]: value });

    recordActions.raiseChanges(catalogId, recordId, values);
  };

  const [items, deletedItems, isItemsExists] = useMemo(() => {
    let deletedItems = Immutable.List();
    let isItemsExists = false;

    const items = props.items
      .filter((item) => {
        if (item.getIn(['item', 'isRemoved'])) {
          deletedItems = deletedItems.push(item);
          return false;
        }
        isItemsExists = true;
        return true;
      })
      .map((item) => {
        const catalogId = item.getIn(['item', 'catalogId']);
        const recordId = item.getIn(['item', 'recordId']);
        const canRestore = item.get('canRestore');

        let record = props.records && props.records.getIn([catalogId, recordId]);
        record = record && record.set('canRestore', canRestore);

        return record;
      });

    return [items, deletedItems, isItemsExists];
  }, [props.items, props.records]);

  const fieldsToRender = useMemo(
    () => prepareFieldsToRender(props.extendedFields, props.userSettings),
    [prepareFieldsToRender],
  );

  const fieldsWidth = useMemo(() => prepareWidth(props.userSettings), [prepareWidth]);

  const [_sortField, _sortType, fieldsOrder] = useMemo(() => {
    const { userSettings } = props;
    const sortField = (userSettings && userSettings.getIn(['sortingRecords', 'sortField'])) || 'id';
    const sortType = (userSettings && userSettings.getIn(['sortingRecords', 'sortType'])) || 1;
    const fieldsOrder = (userSettings && userSettings.getIn(['fieldsOrder', 'fieldsOrder'])) || null;
    return [sortField, sortType, fieldsOrder];
  }, [props.userSettings]);

  /*   const group = (
    <Group
      subTitle={props.catalogTitle}
      countText={trs("record.groupRecordsCount", items.size)}
      title={props.controlConfig.get("name")}
      onClick={props.onChangeTableVisible}
      id={props.catalogId}
      closed={!props.visible}
    />
  ); */

  /*   if (!props.visible) {
    return group;
  } */

  return (
    <>
      {isItemsExists && (
        <div className={styles.linkedRecordsTableWrapper}>
          {props.extendedFields && !props.extendedFields.isEmpty() ? (
            <div className={styles.linkedRecordsTable}>
              <Table
                removable={!props.readOnly && !props.apiOnly}
                maxHeight={450}
                idColumnMinWidth={40}
                fieldsToRender={fieldsToRender}
                allFields={props.extendedFields}
                records={items}
                recordsAppState={props.records}
                rowsCount={items && items.size}
                catalogId={props.catalogId}
                loading={props.catalogLoading}
                // sortField={sortField}
                // sortType={sortType}
                fieldsWidth={fieldsWidth}
                fieldsOrder={fieldsOrder}
                rowByIndex
                editByClick // edit cell by 1 click intead of doubleClick
                scrollToEndByEditing
                headerClassName={styles.linkedRecordsTableHeader}
                sortHeaderCellClassName={styles.sortHeaderCell}
                idColumnClassName={styles.linkedRecordsTableIdColumn}
                configClassName={styles.linkedRecordsTableConfig}
                isCellEditable={isCellEditable}
                onRecordClick={onRecordClick}
                onRefuseEditing={props.onRefuseEditing}
                onChangeRecordValue={onChange}
                onEndCellEditing={onEndEditing}
                onDeleteRow={onRemoveItem}
                onRestoreRow={onRestoreItem}
                // onSortChange={mixCatalogId(props.onSortChange)}
                onReorder={mixCatalogId(props.onReorder)}
                onResize={mixCatalogId(props.onResize)}
              />
            </div>
          ) : (
            <LinkedRecordsInListMode
              sceneId={props.sceneId}
              controlConfig={props.controlConfig}
              items={items}
              readOnly={props.readOnly}
              onRemoveItem={props.onRemoveItem}
            />
          )}
        </div>
      )}
      {deletedItems && (
        // <div className={styles.deletedItemsContainer}>
        <LinkedRecordsInListMode
          sceneId={props.sceneId}
          controlConfig={props.controlConfig}
          items={deletedItems}
          readOnly={props.readOnly}
          onRemoveItem={props.onRemoveItem}
        />
        // </div>
      )}
    </>
  );
};

export default connect(
  LinkedRecordsTable,
  ['catalogs', 'records'],
  ({ showTitle, ...props }, { catalogs, records }) => {
    const catalog = catalogs.get(props.catalogId);

    return {
      catalogFields: catalog.get('fields'),
      catalogTitle: showTitle ? catalog.get('name') : '',
      catalogLoading: catalog.get('loading'),
      records,
      ...props,
    };
  },
);
